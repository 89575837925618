<template>
  <div>
    <div class="d-flex align-center">
      <span class="text-h4 mr-4">New Customer</span>
    </div>
    <v-sheet rounded outlined class="mt-4 pa-6" max-width="750px">
      <v-form v-model="valid1">
        <v-row>
          <v-col cols="12" sm="6"
            ><v-text-field v-model="customer.firstName" label="First Name" outlined :rules="rules"></v-text-field
          ></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field v-model="customer.lastName" label="Last Name" outlined :rules="rules"></v-text-field
          ></v-col>
        </v-row>
      </v-form>
      <v-form v-model="valid2">
        <v-row>
          <v-col cols="12" sm="6"
            ><v-text-field
              v-model="customer.address.postalCode"
              label="Postcal Code"
              outlined
              :rules="postalCodeRules"
              @change="handleAddressChange()"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field
              v-model="houseNumber"
              label="Number"
              outlined
              :rules="numberRules"
              @change="handleAddressChange()"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-form>
      <v-form v-model="valid3">
        <v-row>
          <v-col cols="12" sm="6"
            ><v-text-field v-model="customer.address.street" label="Street" outlined :rules="rules" disabled></v-text-field
          ></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field v-model="customer.address.city" label="City" outlined :rules="rules" disabled></v-text-field
          ></v-col>
          <v-col cols="12"
            ><v-text-field v-model="customer.email" label="Email" outlined :rules="emailRules"></v-text-field
          ></v-col>
          <v-col cols="12"
            ><v-text-field v-model="customer.phoneNumber" label="Phone" outlined :rules="numberRules"></v-text-field
          ></v-col>
        </v-row>
      </v-form>
      <v-btn color="primary" :disabled="!valid" @click="newCustomer()"><v-icon left>mdi-content-save</v-icon>save</v-btn>
    </v-sheet>
  </div>
</template>

<script>
import postcodeService from '@/services/postcodeService';
import portalService from '@/services/portalService';

export default {
  data() {
    return {
      valid1: false,
      valid2: false,
      valid3: false,
      customer: {
        email: null,
        firstName: null,
        lastName: null,
        address: {
          street: null,
          postalCode: null,
          city: null,
        },
        phoneNumber: null,
      },
      houseNumber: '',
      rules: [(v) => !!v || 'Required'],
      emailRules: [(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      postalCodeRules: [(v) => !!v || 'Required', (v) => /^[1-9]\d{3}\s?[a-zA-Z]{2}$/.test(v) || 'PostalCode must be valid'],
      numberRules: [(v) => !!v || 'Required', (v) => /^\d*$/.test(v) || 'Only numbers allowed'],
    };
  },
  methods: {
    newCustomer() {
      console.log(this.customer);
      portalService
        .createNewCustomer(this.customer)
        .then(() => this.$router.push({ name: 'Customers' }))
        .catch((error) => console.error(error));
    },
    getAddress() {
      postcodeService
        .getAddress(this.customer.address.postalCode, this.houseNumber)
        .then((response) => {
          this.customer.address.street = `${response.data.street} ${response.data.number}`;
          this.customer.address.city = response.data.city;
          this.customer.address.postalCode = response.data.postcode;
        })
        .catch((error) => console.error(error));
    },
    handleAddressChange() {
      if (this.valid2) {
        console.log('check address');
        this.getAddress();
      }
    },
  },
  computed: {
    valid() {
      return this.valid1 && this.valid2 && this.valid3;
    },
  },
};
</script>
